import React from 'react';
import { string } from 'prop-types';
import loadable from '@loadable/component';
import { useStaticQuery, graphql } from 'gatsby';

import {
  LOGIN_ROUTE, LANDING_PAGE,
  REGISTRATION_START, SUPPORT_HOME,
} from 'constants/navigation';
import {
  LOGIN_ICON, HOME_NAV,
  REGISTER_ICON, SUPPORT_NAV,
} from 'constants/font-awesome';

import { Typography } from '@material-ui/core';

import useStyles from './styles';

const Page = loadable(() => import('components/page'));
const LazyRegisterBanner = loadable(() => import('components/banners/register'));
const LazyRegistrationHomeView = loadable(() => import('views/registration'));
const LazyOverrideFooter = loadable(() => import('components/footer/override-footer'));

const SuspendedRegistrationStartPage = ({ sub }) => {
  const styles = useStyles();

  const { cmsJson: { pages: { registrationStart } } } = useStaticQuery(
    graphql`
      query  { cmsJson { pages { registrationStart { title message } } } }`,
  );

  const { title, message } = registrationStart;
  return (
      <Page
        title="Registration"
        description={'Welcome to Total Loyalty registration'}>
        <LazyRegisterBanner/>
        <Typography variant="h2" component="h1" className={styles.heading} align="center">
          {title}
        </Typography>
        <Typography variant="h4" component="h2" className={styles.pricesHeading} align="center">
          {message}
        </Typography>
        <LazyRegistrationHomeView sub={sub}/>
        <LazyOverrideFooter
          navItems={[
            { route: LANDING_PAGE, icon: HOME_NAV, message: 'Home' },
            { route: SUPPORT_HOME, icon: SUPPORT_NAV, message: 'Support' },
            { route: LOGIN_ROUTE, icon: LOGIN_ICON, message: 'Login' },
            { route: REGISTRATION_START, icon: REGISTER_ICON, message: 'Register' },
          ]}
          currentItem="Register"
          />
      </Page>
  );
};

SuspendedRegistrationStartPage.propTypes = { sub: string };

export default SuspendedRegistrationStartPage;
